import {addLocale} from 'primereact/api'
import {useEffect, useState} from 'react'
import {toast, ToastContainer} from 'react-toastify'
import {Controller, useForm} from 'react-hook-form'
import moment from 'moment/moment'
import axios from 'axios'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import {MultiSelect} from 'primereact/multiselect'
import {TreeSelect} from 'primereact/treeselect'
import {Button} from 'primereact/button'
import {ProgressSpinner} from 'primereact/progressspinner'
import './form.scss'

const RandevuForm = () => {
    addLocale('az', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['B', 'BE', 'ÇA', 'Ç', 'CA', 'C', 'Ş'],
        monthNames: ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'İyun', 'İyul', 'Avqust', 'Sentyabr', 'Oktaybr', 'Noyabr', 'Dekabr'],
        monthNamesShort: ['Yan', 'Fev', 'Mar', 'Apr', 'May', 'İyn', 'İyl', 'Avq', 'Sen', 'Okt', 'Noy', 'Dek'],
        today: 'Hoy',
        clear: 'Limpiar'
    })
    const [doctors, setDoctors] = useState([])
    const [time, setTime] = useState([])
    const [loading, setLoading] = useState(false)
    const [services, setServices] = useState([])
    const [value, setValue] = useState([])
    const [errMess, setErrMess] = useState(false)
    const notifySuccess = () => toast.success('Uğurla alındı', {
        position: toast.POSITION.TOP_RIGHT
    })
    const notifyError = () => toast.error('X', {
        position: toast.POSITION.TOP_RIGHT
    })
    const prefixElement = prefix => {
        return {
            value: prefix,
            label: prefix
        }
    }
    const prefixes = [
        prefixElement('050'),
        prefixElement('051'),
        prefixElement('055'),
        prefixElement('070'),
        prefixElement('077'),
        prefixElement('099'),
        prefixElement('010'),
        prefixElement('060')
    ]

    const {control, handleSubmit, watch, reset, formState: {errors}} = useForm({
        defaultValues: {
            phone_prefix: prefixes[0].value
        }
    })

    const doctorId = watch('doctor_id')
    const date = watch('date')
    const [isFetching, setIsFetching] = useState(false)
    const loadingProcess = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }
    const CustomOption = (data) => {
        return <span>
            <span>{data.label}</span>{' '}
            {data?.is_disabled === 1 && <span style={{color:'red'}}>
                {'(Rezerv edilib)'}
            </span>}
        </span>
    }
    const submit = async (data) => {
        const localData = {
            ...data,
            date: moment(data?.date).format('YYYY-MM-DD'),
            hour:value
        }
        if (value.length === 0) {
            setErrMess(true)
        } else {
            setErrMess(false)
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/patient-meeting/guest`, localData)
                reset({
                    name: '',
                    surname: '',
                    phone: ''
                })
                loadingProcess()
                setTimeout(() => {
                    notifySuccess()
                }, 500)
            } catch (error) {
                loadingProcess()
                setTimeout(() => {
                    notifyError()
                }, 500)
            }
        }
    }
    const fetchDoctors = async () => {
        setIsFetching(true)
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/doctors/select`, {
            params: {
                check_role: 1
            }
        })
        setDoctors(data.data.data)
        setIsFetching(false)
    }
    const getChildren = data => {
        return data?.map(item => {
            return {
                ...item,
                key: item?.value,
                children: getChildren(item?.children)
            }
        })
    }

    const fetchData = async () => {
        setIsFetching(true)
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/services`)
        setServices(data?.data?.data?.map(item => {
            return {
                ...item,
                key: item?.value,
                children: getChildren(item?.children)
            }
        }))
        setIsFetching(false)
    }
    const getHours = async () => {
        setTime([])
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/patient-meeting/doctor-hours`, {
            params: {
                doctor_id: doctorId,
                date: moment(date).format('YYYY-MM-DD')
            }
        })
        setTime(data.data.data)
    }

    useEffect(() => {
        if (doctorId && date) {
            getHours()
        }
    }, [doctorId, date])
    useEffect(() => {
        fetchDoctors()
        fetchData()
    }, [])
    return (
        <div className="randevu-form" id="online-randevu">
            {
                isFetching ? (
                    <div className="flex mt-5 justify-content-center align-items-center">
                        <ProgressSpinner />
                    </div>
                ) : (<form onSubmit={handleSubmit(submit)} className="form">
                    <div className="mb-4 flex flex-column">
                        <label htmlFor="name">Ad</label>
                        <Controller rules={{
                            required: true
                        }} render={({field:{value, onChange}}) => (
                            <InputText name="name" placeholder="Daxil edin" id="name" value={value} onChange={onChange} />
                        )} name="name" control={control} />
                        {errors.name && (
                            <small className="p-error">
                                Xananın daxil edilməsi zəruridir
                            </small>
                        )}
                    </div>
                    <div className="mb-4 flex flex-column">
                        <label htmlFor="surname">Soyad</label>
                        <Controller rules={{
                            required: true
                        }} render={({field:{value, onChange}}) => (
                            <InputText name="surname" placeholder="Daxil edin" id="surname" value={value} onChange={onChange} />
                        )} name="surname" control={control} />
                        {errors.surname && (
                            <small className="p-error">
                                Xananın daxil edilməsi zəruridir
                            </small>
                        )}
                    </div>
                    <div className="mb-4 flex flex-column">
                        <label htmlFor="phone">Mobil nömrə</label>
                        <div className="w-full flex align-items-start gap-1">
                            <Controller rules={{
                                required: true
                            }} render={({field:{value, onChange}}) => (
                                <Dropdown
                                    value={value}
                                    onChange={onChange}
                                    options={prefixes}
                                    name="phone_prefix"
                                    className="w-14" />
                            )} name="phone_prefix" control={control} />
                            <Controller render={({field:{value, onChange}}) => (
                                <InputText name="phone" className="w-full" placeholder="Daxil edin" id="phone" value={value} onChange={onChange} />
                            )} name="phone" control={control} />

                        </div>
                    </div>
                    <div className="mb-4 flex flex-column">
                        <label htmlFor="doctor_id">Həkim</label>
                        <Controller rules={{
                            required: true
                        }} render={({field:{value, onChange}}) => (
                            <Dropdown
                                value={value}
                                onChange={onChange}
                                options={doctors}
                                name="doctor_id"
                                id="doctor_id"
                                className="w-14"
                                placeholder="Daxil edin"
                            />
                        )} name="doctor_id" control={control} />
                        {errors.doctor_id && (
                            <small className="p-error">
                                Xananın daxil edilməsi zəruridir
                            </small>
                        )}
                    </div>
                    <div className="mb-4 flex flex-column">
                        <label htmlFor="service_id">Xidmətlər</label>
                        <Controller control={control} render={({field:{value, onChange}}) => (
                            <>
                                <TreeSelect
                                    id="parent_id"
                                    value={value}
                                    onChange={onChange}
                                    options={services}
                                    name="parent_id"
                                    selectionMode="single"
                                    placeholder="Daxil edin"
                                    className="w-full" />
                            </>
                        )} name="service_id" />
                        {errors.service_id && (
                            <small className="p-error">
                                Xananın daxil edilməsi zəruridir
                            </small>
                        )}
                    </div>
                    <div className="mb-4 flex flex-column">
                        <label htmlFor="date">Tarix</label>
                        <Controller rules={{
                            required: true
                        }} render={({field:{value, onChange}}) => (
                            <Calendar
                                locale="az"
                                dateFormat="dd/mm/yy"
                                placeholder="Tarix" className="w-full" value={value}
                                onChange={(e) => onChange(e.value)} id="date"
                            />
                        )} name="date" control={control} />
                        {errors.date && (
                            <small className="p-error">
                                Xananın daxil edilməsi zəruridir
                            </small>
                        )}
                    </div>
                    {
                        doctorId && date &&
                        <div className="mb-4 flex flex-column">
                            <label htmlFor="hour">Saat</label>
                            <Controller  render={({field}) => (
                                <MultiSelect
                                    value={value}
                                    onChange={e => {
                                        setValue(e.value)
                                    }}
                                    multiple={true}
                                    options={time}
                                    maxSelectedLabels={0}
                                    optionDisabled={e => e.is_disabled}
                                    selectedItemsLabel={`${value?.length} element seçildi`}
                                    name="hour"
                                    id="hour"
                                    placeholder="Daxil edin"
                                    className="w-full"
                                    optionLabel={CustomOption}
                                />
                            )} name="hour" control={control} />
                            {errMess && (
                                <small className="p-error">
                                        Xananın daxil edilməsi zəruridir
                                </small>
                            )}
                        </div>
                    }

                    <Button className="p-button-danger w-full" type="submit">
                        {loading && <i className='pi pi-spin pi-spinner mr-2' ></i>} Randevu Yarat
                    </Button>
                    <ToastContainer autoClose={700} />
                </form>)
            }

        </div>
    )
}

export default RandevuForm
