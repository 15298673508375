import DoctorsPhoto from '../../../assets/images/doctors1.png'
import './about.scss'
const AboutUs = () => {
    return (
        <div className='about-us'>
            <div className="left">
                <div className="about-title">Haqqımızda</div>
                <div className="question"><span>Biz</span> Kimik?</div>
                <div className="about-desc">
                    Biz mehriban və rahat mühitdə müstəsna stomatoloji xidmət göstərməyi qərara aldığımız Smile Dentala xoş gəlmisiniz. Təcrübəli stomatoloqlar komandamız sizə optimal ağız sağlamlığına nail olmaq və saxlamaqda kömək etməyə sadiqdir.<br/>
                    Smile Dentalda biz rutin müayinələr, təmizləmələr, plomblar, kök kanalları, çəkilişlər, dişlərin ağardılması, ortodontik müalicələr və diş əti xəstəliklərinin müalicəsi daxil olmaqla geniş çeşiddə stomatoloji xidmətlər təklif edirik. Sizə ən yüksək keyfiyyətli qulluq göstərməyinizi təmin etmək üçün ən son stomatoloji texnologiya və texnikalardan istifadə edirik.<br />
                </div>
            </div>
            <div className="right">
                <img src={DoctorsPhoto} alt=""/>
            </div>
        </div>
    )
}

export default AboutUs
