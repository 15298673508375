import React from 'react'
const Address = () => {
    return (
        <div className='address'>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.158012155151!2d49.74866097552945!3d40.44963955363651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40308509f3f2426d%3A0xa3f8fe76e814fc4e!2sSmile%20Dental!5e0!3m2!1str!2saz!4v1712144366060!5m2!1str!2saz"
                height="300px"
                id="address"
                width="100%"
                allowFullScreen={true}
                style={{border: 'none'}}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade" />
        </div>
    )
}

export default Address
